import React, { Component } from "react";
import { Link } from "react-router-dom";
import { setFilters, clearFilters, getFilters } from "utils/localStorageServices";
//  Images

class SideBar extends Component {
  // state = {
  // loadingSchedular: false,
  // showSchedularModal: false,
  // };
  componentDidUpdate = () => {
    const { page } = this.props;
    if (page !== "games") {
      clearFilters("paid-games");
    }
  };
  render() {
    const { page } = this.props;
    return (
      <>
        <div className="page-sidebar">
          <div className="page-sidebar-inside hide-scrollbar">
            <ul className="menu-list">
              <li className={`menu-item has-menu  ${page === "dashboard" && "active"}`}>
                <Link to="/dashboard/zed-run">
                  Dashboard
                  <span className="angle-arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
                <ul className="sidebar-submenu">
                  <li className={`sidebar-submenu-item `}>
                    <Link to="/dashboard/zed-run">Zed Run</Link>
                  </li>
                  <li className={`sidebar-submenu-item `}>
                    <Link to="/dashboard/robot-racer">Robot Racer</Link>
                  </li>
                  <li className={`sidebar-submenu-item `}>
                    <Link to="/dashboard/cowitzer">Cowitzer</Link>
                  </li>
                  <li className={`sidebar-submenu-item `}>
                    <Link to="/dashboard/lootbox">Loot Box</Link>
                  </li>
                  <li className={`sidebar-submenu-item `}>
                    <Link to="/dashboard/verisoul">Verisoul Authentication Stats</Link>
                  </li>{" "}
                  <li className={`sidebar-submenu-item `}>
                    <Link to="/dashboard/verisoul-linked-account">Verisoul Linked Accounts</Link>
                  </li>
                  <li className={`sidebar-submenu-item `}>
                    <Link to="/dashboard/referrals">Referrals</Link>
                  </li>
                </ul>
              </li>
              <li className={`menu-item  ${page === "bgGames" && "active"}`}>
                <Link to="/burn-ghost-games">
                  BG Games
                  <span className="angle-arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
              </li>
              <li className={`menu-item has-menu  ${page === "games" && "active"}`}>
                <Link to="/games?amountType=1">
                  Games Played
                  <span className="angle-arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
                <ul className="sidebar-submenu">
                  <li className={`sidebar-submenu-item `}>
                    <Link to="/games?amountType=1">Arcade Games</Link>
                  </li>
                  <li className={`sidebar-submenu-item `}>
                    <Link to="/games?amountType=3">Loot Bucks Games</Link>
                  </li>
                </ul>
              </li>

              <li className={`menu-item has-menu  ${page === "contests" && "active"}`}>
                <Link to="/contests">
                  Contests
                  <span className="angle-arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
              </li>
              {/* <li className={`menu-item has-menu ${page?.includes("sweepstakes") && "active"}`}>
                <Link to="/sweepstakes/contracts">
                  Sweepstakes
                  <span className="angle-arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
                <ul className="sidebar-submenu">
                  <li className={`sidebar-submenu-item `}>
                    <Link to="/sweepstakes/contracts">Contracts</Link>
                  </li>{" "}
                  <li className={`sidebar-submenu-item`}>
                    <Link to="/sweepstakes/instances?status=1">
                      Instances{" "}
                      <span className="angle-arrow">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                    </Link>
                    <ul class="level-three">
                      <li class="child-item">
                        <Link to="/sweepstakes/instances?status=1">Active/Paused</Link>
                      </li>
                      <li class="child-item">
                        <Link to="/sweepstakes/instances?status=2">Action Needed</Link>
                      </li>
                      <li class="child-item">
                        <Link to="/sweepstakes/instances?status=3">Completed</Link>
                      </li>
                      <li class="child-item">
                        <Link to="/sweepstakes/instances?status=4">Canceled</Link>
                      </li>
                    </ul>
                  </li>
                  <li className={`sidebar-submenu-item `}>
                    <Link to="/sweepstakes/currencies">Currency</Link>
                  </li>
                </ul>
              </li> */}
              <li className={`menu-item  ${page === "users" && "active"}`}>
                <Link to="/users">
                  Users
                  <span className="angle-arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
              </li>
              {/* <li className={`menu-item  ${page === "payments" && "active"}`}>
                <Link to="/payments">
                  Payments
                  <span className="angle-arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
              </li> */}
              {/* <li className={`menu-item  ${page === "amoe" && "active"}`}>
                <Link to="/amoe">
                  AMOE
                  <span className="angle-arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
              </li> */}
              <li className={`menu-item has-menu ${page === "winners" && "active"}`}>
                <Link to="/contest-winners?status=open">
                  Contest Winners
                  <span className="angle-arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
                <ul className="sidebar-submenu">
                  <li className={`sidebar-submenu-item `}>
                    <Link to="/contest-winners?status=open">Action Needed</Link>
                  </li>{" "}
                  <li className={`sidebar-submenu-item `}>
                    <Link to="/contest-winners?status=closed">Closed</Link>
                  </li>{" "}
                </ul>
              </li>
              <li className={`menu-item  ${page === "lootbox" && "active"}`}>
                <Link to="/lootbox">
                  Loot Boxes
                  <span className="angle-arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
              </li>
              {/* <li
                className={`menu-item has-menu ${
                  (page === "boardGameCards" ||
                    page === "boardGameColorMap" ||
                    page === "boardGameTournaments" ||
                    page === "boardGameSpinner" ||
                    page === "coinRewardConfig" ||
                    page === "diceRewardConfig" ||
                    page === "boardGameSlot") &&
                  "active"
                }`}
              >
                <Link to="/board-game/settings/cards">
                  Meta Game
                  <span className="angle-arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
                <ul className="sidebar-submenu">
                  <li className={`sidebar-submenu-item ${page === "boardGameCards" && "active"}`}>
                    <Link to="/board-game/settings/cards">Player Cards</Link>
                  </li>{" "}
                  <li className={`sidebar-submenu-item ${page === "boardGameColorMap" && "active"}`}>
                    <Link to="/board-game/settings/color-map">Color Maps</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "boardGameTournaments" && "active"}`}>
                    <Link to="/board-game/settings/tournaments?status=pending">Tournaments</Link>
                    <ul class="level-three">
                      <li class="child-item">
                        <Link to="/board-game/settings/tournaments?status=pending">Pending</Link>
                      </li>
                      <li class="child-item">
                        <Link to="/board-game/settings/tournaments?status=active">Active</Link>
                      </li>
                      <li class="child-item">
                        <Link to="/board-game/settings/tournaments?status=ended">Ended</Link>
                      </li>
                    </ul>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "boardGameSpinner" && "active"}`}>
                    <Link to="/board-game/settings/spinner">Spinner Rewards</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "boardGameSlot" && "active"}`}>
                    <Link to="/board-game/settings/slot">Slot Rewards</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "boardGameConfig" && "active"}`}>
                    <Link to="/board-game/settings/game-config">BG game config</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "coinRewardConfig" && "active"}`}>
                    <Link to="/board-game/settings/coin-reward-config">Coin Reward config</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "diceRewardConfig" && "active"}`}>
                    <Link to="/board-game/settings/dice-reward-config">Dice Reward config</Link>
                  </li>
                </ul>
              </li> */}
              <li className={`menu-item has-menu ${page?.includes("telegram-mini-app") && "active"}`}>
                <Link to="/telegram/settings/users">
                  Telegram App
                  <span className="angle-arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
                <ul className="sidebar-submenu">
                  <li className={`sidebar-submenu-item ${page === "telegram-mini-app-users" && "active"}`}>
                    <Link to="/telegram/settings/users">Users</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "telegram-mini-app-games" && "active"}`}>
                    <Link to="/telegram/settings/games">Games</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page.includes("telegram-mini-app-luckGame") && "active"}`}>
                    <Link to="/telegram/settings/zrun/">
                      Luck Games
                      <span className="angle-arrow">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                    </Link>
                    <ul class="level-three">
                      <li className={`child-item ${page === "telegram-mini-app-luckGame-zrun" && "active"}`}>
                        <Link to="/telegram/settings/zrun/">Zed Run</Link>
                      </li>
                      <li className={`child-item ${page === "telegram-mini-app-luckGame-cowitzer" && "active"}`}>
                        <Link to="/telegram/settings/cowitzer/">Cowitzer</Link>
                      </li>
                      <li className={`child-item ${page === "telegram-mini-app-luckGame-robot-racer" && "active"}`}>
                        <Link to="/telegram/settings/robot-racer/">Robot Racer</Link>
                      </li>
                    </ul>
                  </li>
                  <li className={`sidebar-submenu-item ${page.includes("telegram-mini-app-sweepstake") && "active"}`}>
                    <Link to="/telegram/settings/sweepstakes/">
                      Sweepstakes{" "}
                      <span className="angle-arrow">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                    </Link>
                    <ul class="level-three">
                      <li class={`child-item ${page === "telegram-mini-app-sweepstake-prizes" && "active"}`}>
                        <Link to="/telegram/settings/sweepstakes/prizes">Prizes Templates</Link>
                      </li>
                      <li class={`child-item ${page === "telegram-mini-app-sweepstake-template" && "active"}`}>
                        <Link to="/telegram/settings/sweepstakes/templates">Sweepstakes Templates</Link>
                      </li>
                      <li class="child-item">
                        <Link to="/telegram/settings/sweepstakes/?status=active">Active</Link>
                      </li>
                      <li class="child-item">
                        <Link to="/telegram/settings/sweepstakes/?status=ended">Completed</Link>
                      </li>
                      <li class="child-item">
                        <Link to="/telegram/settings/sweepstakes/?status=scheduled">Scheduled</Link>
                      </li>
                      <li class="child-item">
                        <Link to="/telegram/settings/sweepstakes/?status=cancelled">Canceled</Link>
                      </li>
                      <li class="child-item">
                        <Link to="/telegram/settings/sweepstakes/">All</Link>
                      </li>
                    </ul>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "telegram-mini-app-daily-tournament" && "active"}`}>
                    <Link to="/telegram/settings/daily-tournament">Daily Tournament</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "telegram-mini-app-team-play" && "active"}`}>
                    <Link to="/telegram/settings/team-play">Team Play Challenges</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "telegram-mini-app-quest" && "active"}`}>
                    <Link to="/telegram/settings/quests">Quests</Link>
                  </li>{" "}
                  <li className={`sidebar-submenu-item ${page === "telegram-mini-app-challenges" && "active"}`}>
                    <Link to="/telegram/settings/challenges">Player Challenges</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "telegram-mini-app-usdt" && "active"}`}>
                    <Link to="/telegram/settings/usdtWithdrawal">USDT Withdrawal Requests</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page.includes("telegram-mini-app-sweepstake") && "active"}`}>
                    <Link to="/telegram/settings/spinner/">
                      Settings{" "}
                      <span className="angle-arrow">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </span>
                    </Link>
                    <ul class="level-three">
                      <li className={`child-item ${page === "telegram-mini-app-misc" && "active"}`}>
                        <Link to="/telegram/settings/misc-settings">Misc Settings</Link>
                      </li>{" "}
                      <li className={`child-item ${page === "telegram-mini-app-spinner" && "active"}`}>
                        <Link to="/telegram/settings/spinner">Spinner Rewards</Link>
                      </li>{" "}
                      <li className={`child-item ${page === "telegram-mini-app-verisoul-auth" && "active"}`}>
                        <Link to="/telegram/settings/verisoul">Verisoul Authentication Stats</Link>
                      </li>{" "}
                      <li className={`child-item ${page === "telegram-mini-app-verisoul-linked" && "active"}`}>
                        <Link to="/telegram/settings/verisoul-linked-account">Verisoul Linked Accounts</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li
                className={`menu-item has-menu ${
                  (page === "contestTemplate" ||
                    page === "customText" ||
                    page === "customSliders" ||
                    page === "nftCredits" ||
                    page === "upload-csv" ||
                    page === "newsLetter" ||
                    page === "winnerTemplate" ||
                    page === "waitList" ||
                    page === "reports") &&
                  "active"
                }`}
              >
                <Link to="/settings/packages">
                  Settings
                  <span className="angle-arrow">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 3L11 8L6 13" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </Link>
                <ul className="sidebar-submenu">
                  <li className={`sidebar-submenu-item `}>
                    <Link to="/settings/packages">Packages</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "customSliders" && "active"}`}>
                    <Link to="/custom-sliders">Custom Slides</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "credits" && "active"}`}>
                    <Link to="/settings/blockchain-networks">Blockchain Network</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "newsLetter" && "active"}`}>
                    <Link to="/newsletter-subscribers">Newsletter Subscribers</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "waitList" && "active"}`}>
                    <Link to="/wait-list">Waiting List</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "geolocation" && "active"}`}>
                    <Link to="/geolocation">Geolocation</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "leaderboardCredits" && "active"}`}>
                    <Link to="/settings/misc-settings">Misc Settings</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "winnerTemplate" && "active"}`}>
                    <Link to="/how-it-works-template">How It Works Template</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "winnerTemplate" && "active"}`}>
                    <Link to="/settings/contest-templates">Contest Templates</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "winnerTemplate" && "active"}`}>
                    <Link to="/settings/sweepstakes-templates">Sweepstakes Templates</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "reports" && "active"}`}>
                    <Link to="/settings/coin-balance-report">Coin Balance Report</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "reports" && "active"}`}>
                    <Link to="/settings/weekly-leaderboard">Weekly Leaderboard</Link>
                  </li>
                  <li className={`sidebar-submenu-item ${page === "ticket-redemption" && "active"}`}>
                    <Link to="/settings/ticket-redemption-requests">Ticket Redemption Requests</Link>
                  </li>{" "}
                  {/* Maintenance Mode */}
                  <li className={`sidebar-submenu-item ${page === "maintenance" && "active"}`}>
                    <Link to="/maintenance">Maintenance Mode</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}
export default SideBar;
