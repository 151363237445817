import moment from "moment";

export const getNumberWithOrdinal = (n) => {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
};
export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(/^(0x[a-zA-Z0-9]{3})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/);
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};
export const msToHMS = (ms = 0) => {
  // 1- Convert to seconds:
  let seconds = parseInt(ms / 1000);
  // 2- Extract hours:
  const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
  seconds = parseInt(seconds % 3600); // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = parseInt(seconds % 60);
  const hoursStr = ("00" + hours).slice(-2);
  const minutesStr = ("00" + minutes).slice(-2);
  const secondsStr = ("00" + seconds).slice(-2);
  return hoursStr + ":" + minutesStr + ":" + secondsStr;
};
// Function to check if a given day is greater than today in UTC
export const isDayGreaterThanTodayInUTC = (day) => {
  const givenDayUTC = moment.utc(day); // Convert the given day to UTC
  const todayUTC = moment.utc().startOf("day"); // Get the current day in UTC and set the time to the start of the day
  return givenDayUTC.isAfter(todayUTC); // Check if the given day is after today
};
// Function to check if a given day is less than today in UTC
export const isDayLessThanTodayInUTC = (day) => {
  const givenDayUTC = moment.utc(day); // Convert the given day to UTC
  const todayUTC = moment.utc().startOf("day"); // Get the current day in UTC and set the time to the start of the day
  return givenDayUTC.isBefore(todayUTC); // Check if the given day is before today
};

export const pluralize = (count, alwaysSingular = false, singular, plural = `${singular}s`) => {
  if (alwaysSingular) {
    return singular; // Return the singular form if alwaysSingular is true
  }
  return count === 1 ? singular : plural;
};
