import Joi from "joi-browser";
import React from "react";
import { Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";

//  Images
import closeIcon from "include/images/dark-close-icon.svg";
import userIcon from "include/images/user.png";
import plusIcon from "include/images/plus-1.svg";
import deleteIcon from "include/images/delete-icon.svg";
import placeholderJson from "include/json/placeholder-img.json";

//  Components
import AlertError from "common/alert/alertError";
import AlertSuccess from "common/alert/alertSuccess";
import Form from "common/form/form";
import NextButton from "common/form/submitButton";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { sendBroadcastNotifications, sendUserNotifications } from "store/telegram";
import { v4 as uuidv4 } from "uuid";
import Amplify, { Auth, Storage } from "aws-amplify";
import Lottie from "react-lottie-player";
import {
  TELEGRAM_NOTIFICATION_CHARACTER_COUNT,
  TELEGRAM_NOTIFICATION_CHARACTER_COUNT_WITH_IMAGE,
} from "utils/constants";

//  Amplify Configuration
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    region: "us-east-1",
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET,
    region: "us-east-1",
  },
});
Auth.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    bucket: process.env.REACT_APP_S3BUCKET, //REQUIRED -  Amazon S3 bucket
    region: "us-east-1",
  },
});
class SendNotificationFlyer extends Form {
  fileInputRef = React.createRef(null);
  state = {
    loading: false,
    data: {
      message: EditorState.createEmpty(),
      image: "",
    },
    loadingImage: false,
    errors: {},
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.showModal !== prevProps.showModal) {
      this.clearData();
    }

    if (
      prevState?.data?.image !== this.state.data?.image ||
      prevState?.data?.message?.getCurrentContent()?.getPlainText("")?.length !==
        this.state.data?.message?.getCurrentContent()?.getPlainText("")?.length
    ) {
      const msgLength = this.state.data?.message?.getCurrentContent()?.getPlainText("")?.length;
      if (
        (this.state.data?.image && msgLength > TELEGRAM_NOTIFICATION_CHARACTER_COUNT_WITH_IMAGE) ||
        (!this.state.data?.image && msgLength > TELEGRAM_NOTIFICATION_CHARACTER_COUNT)
      ) {
        this.setState({ errors: { message: "Message exceeded word limit" } });
      } else if (this.state.errors?.message) {
        this.setState({ errors: {} });
      }
    }
  };
  doSubmit = (e) => {
    const data = { ...this.state.data };

    if (this.state.errors?.message) return;

    // Check if the message content is empty or contains only whitespace
    if (
      !data?.message?.getCurrentContent()?.hasText() ||
      data?.message?.getCurrentContent()?.getPlainText("")?.trim()?.length === 0
    ) {
      this.setState({ errors: { message: "Message is required" } });
      return;
    }
    this.setState({ loading: true });

    const payLoad = {
      message: stateToHTML(data?.message?.getCurrentContent(), {
        defaultBlockTag: null,
      })?.replace(/&nbsp;/g, ""),
    };
    if (data?.image) payLoad.image = data?.image;

    if (this.props?.user === "all") {
      this.props.sendBroadcastNotifications(payLoad, (res) => {
        this.setState({
          loading: false,
        });
        if (res?.status === 200) {
          toast(<AlertSuccess message="Notification sent successfully" />, {
            containerId: 1,
          });
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    } else {
      payLoad.telegramIds = [this.props?.user?.telegramId];

      this.props.sendUserNotifications(payLoad, (res) => {
        this.setState({
          loading: false,
        });
        if (res?.status === 200) {
          toast(<AlertSuccess message="Notification sent successfully" />, {
            containerId: 1,
          });
          this.props.toggleModal();
        } else {
          toast(<AlertError message={res.data && res.data.message} />, {
            containerId: 1,
          });
        }
      });
    }
  };
  schema = {
    message: Joi.object().required(),
    image: Joi.string().allow(""),
  };
  clearData = () => {
    this.setState({
      loading: false,
      data: {
        message: EditorState.createEmpty(),
        image: "",
      },
      errors: {},
    });
  };

  handleImageChange = (e) => {
    const errors = { ...this.state.errors };
    this.setState({ loadingImage: true });
    const fSize = Math.round(e.target.files[0].size / 1048576);
    const fType = e.target.files[0].type;
    const ext = e.target.files[0].name.split(".").pop();
    if (fSize > 10) {
      toast(<AlertError message="Image size exceeds maximum allowable size. Maximum allowable size is 10MB." />, {
        containerId: 1,
      });
      return this.setState({ loadingImage: false });
    } else if (!["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(fType)) {
      toast(
        <AlertError message="File is not of correct format and hence cannot be uploaded. Valid image formats are PNG, JPG, JPEG and WEBP." />,
        {
          containerId: 1,
        },
      );
      return this.setState({ loadingImage: false });
    } else {
      const fileName = uuidv4() + "." + ext;
      Storage.put(fileName, e.target.files[0], {
        completeCallback: (event) => {},
        progressCallback: (progress) => {},
        errorCallback: (err) => {},
      }).then((result) => {
        this.setState({
          errors,
          loadingImage: false,
          data: {
            ...this.state.data,
            image: "public/" + result.key,
          },
        });
      });
    }
    e.target.value = null;
  };
  onChange = (e) => {
    const data = this.state.data;
    data[e.name] = e.currentTarget;
    this.setState({ data });
  };
  render() {
    const user = this.props.user;
    const characterCount = this.state.data.message.getCurrentContent().getPlainText("").length;
    return (
      <Offcanvas
        className={"offcanvas offcanvas-end custom-offcanvas-pannel "}
        show={this.props.showModal}
        onHide={this.props.toggleModal}
        id="rewardPannel"
        placement="right"
        name="rewardPannel"
        backdrop={true}
      >
        <Offcanvas.Header className="offCanvas-header-2">
          <div>
            <h5>{typeof user === "object" ? "Send Notification" : "Broadcast Telegram Notification"}</h5>{" "}
            {typeof user === "object" && (
              <div className="td-img-group d-flex align-items-start m-top-2">
                <div className="td-img round-img m-right-2 pointer">
                  <img src={user?.image?.small ? process.env.REACT_APP_CDN + user.image.small : userIcon} alt="" />
                </div>
                {user?.firstName} {user?.lastName}
                <br />
                {user?.userName}
              </div>
            )}
          </div>
          <div
            className="btn_close pointer"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={this.props.toggleModal}
          >
            <img src={closeIcon} alt="" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-check-outer">
            <div className="new-form-group">
              <div className="row">
                <div className="col-12 new-form-group">
                  {this.renderTextareaEditor(
                    "message",
                    <>
                      Message (Characters {characterCount}/
                      {this.state.data?.image
                        ? TELEGRAM_NOTIFICATION_CHARACTER_COUNT_WITH_IMAGE
                        : TELEGRAM_NOTIFICATION_CHARACTER_COUNT}
                      )
                    </>,
                    "",
                    "",
                  )}
                </div>
              </div>
            </div>
            <div className="new-form-group">
              <div className="kt-upload-row d-flex flex-wrap align-items-start">
                {!this.state.loadingImage ? (
                  <div
                    className={`kt-upload-outer kt-upload-outer-banner-3 ${
                      !this.state.data?.image
                        ? "placeholder-uploaded-img"
                        : "kt-upload-outer-banner-2-h-100  position-relative"
                    } ${this.state.errors.image && "border-error"}`}
                  >
                    <div className="kt-upload-img" htmlFor="uploadImg">
                      <img
                        src={this.state?.data?.image ? process.env.REACT_APP_CDN + this.state?.data?.image : plusIcon}
                        alt=""
                      />
                    </div>
                    <div
                      className="delete-icon d-flex align-items-center justify-content-center"
                      onClick={(e) => {
                        const data = { ...this.state.data };
                        data.image = "";
                        this.setState({ data });
                      }}
                    >
                      <img src={deleteIcon} alt="delete" />
                    </div>
                  </div>
                ) : (
                  <div className="kt-upload-outer  kt-upload-outer-banner-3 position-relative loading">
                    <div className="lottie-player-box">
                      <Lottie background="transparent" speed="1" loop autoplay animationData={placeholderJson} play />
                    </div>
                  </div>
                )}
                <div className="kt-file-input">
                  <label htmlFor="formFile" className="kt-label" id="uploadImg">
                    {this.state.data.image?.original ? " Change Image" : "+ Upload Image"}
                  </label>
                  <input className="form-control d-none" type="file" id="formFile" onChange={this.handleImageChange} />
                </div>
              </div>
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <NextButton
            classData="btn btn-default btn-block h-100"
            label="Send"
            loading={this.state.loading}
            handleSubmit={this.doSubmit}
          />
        </div>
      </Offcanvas>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  sendUserNotifications: (data, callback) => dispatch(sendUserNotifications(data, callback)),
  sendBroadcastNotifications: (data, callback) => dispatch(sendBroadcastNotifications(data, callback)),
});

export default withRouter(connect(null, mapDispatchToProps)(SendNotificationFlyer));
